const cls = {
  main: "h-full overflow-y-auto overflow-x-hidden",
  mainWrapper: "px-0 grid mx-auto w-full",
};

const Content = ({ children }) => (
  <main className={cls.main} id="main-container">
    <div className={cls.mainWrapper}>{children}</div>
  </main>
);

export default Content;
