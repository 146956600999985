const mainRoutes = [
  {
    exact: true,
    icon: "DiamondIcon",
    name: "Join Us",
    path: "/join",
  },
  {
    exact: true,
    icon: "SpeakerIcon",
    name: "FAQ",
    path: "/faq",
  },
  {
    exact: true,
    icon: "GiftIcon",
    name: "Hire From Us",
    path: "/hire",
  },
  {
    exact: true,
    icon: "QnAIcon",
    name: "Contact Us",
    path: "/contact",
  },
];

export { mainRoutes };
