import "./style.css";

const Loader = () => {
  return (
    <div id="preloader" className="dark:bg-gray-900">
      <div id="loader" />
    </div>
  );
};

export default Loader;
