import { lazy, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import routes from "../../../router/routes";
import Header from "../components/header/Header";
import Content from "../content/Content";
import NavSidebar from "../components/nav-sidebar/NavSidebar";
import ScrollToTop from "../../common/ScrollToTop";

const NotFoundPage = lazy(() => import("../../../pages/NotFoundPage"));

const cls = {
  layout:
    "flex h-screen bg-light-mode-content-bg dark:bg-dark-mode-content-bg text-gray-500 dark:text-gray-200",
  mainWrapper: "flex flex-col flex-1 w-full",
};

const LayoutContainer = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    if (!localStorage.twTheme) return;
    document.querySelector("html").classList.add(localStorage.twTheme);
  }, []);

  return (
    <>
      <div className={cls.layout}>
        <NavSidebar
          isSidebarOpen={isSidebarOpen}
          handleIsSidebarOpen={() => setIsSidebarOpen((prevFlag) => !prevFlag)}
        />
        <div className={cls.mainWrapper}>
          <Header
            handleIsSidebarOpen={() =>
              setIsSidebarOpen((prevFlag) => !prevFlag)
            }
          />
          <Content>
            <ScrollToTop />
            <Switch>
              {routes.map((route) =>
                route.component ? (
                  <Route
                    key={route.path}
                    exact={true}
                    path={`${route.path}`}
                    render={(props) => <route.component {...props} />}
                  />
                ) : null
              )}

              <Route component={NotFoundPage} />
            </Switch>
          </Content>
        </div>
      </div>
    </>
  );
};

export default LayoutContainer;
