import { lazy } from "react";

const TermsAndCondition = lazy(() =>
  import("../pages/TermsAndCondition/TermsAndCondition")
);
const PrivacyPolicy = lazy(() =>
  import("../pages/PrivacyPolicy/PrivacyPolicy")
);
const HomePage = lazy(() => import("../pages/HomePage/HomePage"));
const ContactPage = lazy(() => import("../pages/ContactPage/ContactPage"));
const HireFromUsPage = lazy(() =>
  import("../pages/HireFromUsPage/HireFromUsPage")
);
const JoinUsPage = lazy(() => import("../pages/JoinUsPage/JoinUsPage"));

const FaqPage = lazy(() => import("../pages/FaqPage/FaqPage"));

const routes = [
  {
    component: HomePage, // view rendered
    path: "/", // the url
  },
  {
    component: ContactPage,
    path: "/contact",
  },
  {
    component: HireFromUsPage,
    path: "/hire",
  },
  {
    component: JoinUsPage,
    path: "/join",
  },
  {
    component: FaqPage,
    path: "/faq",
  },
  {
    component: TermsAndCondition,
    path: "/terms",
  },
  {
    component: PrivacyPolicy,
    path: "/privacy",
  },
];

export default routes;
