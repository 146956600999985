import { useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";

const ScrollToTop = () => {
  const history = useHistory();

  useLayoutEffect(() => {
    const unlisten = history.listen(() => {
      document.getElementById("main-container")?.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default ScrollToTop;
