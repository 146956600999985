import { NavLink, Route } from "react-router-dom";
import { mainRoutes } from "../../../../router/sidebar";
import SvgIcon from "../../../common/SvgIcon";

const SidebarContent = ({ handleIsSidebarOpen }) => (
  <div className="py-4 pt-6 text-gray-700 dark:text-gray-400">
    <ul className="mt-10 lg:mt-9">
      <li className="relative px-6 py-3 ">
        <NavLink to="/" className="hidden lg:block">
          {/* <SvgIcon className="w-full h-8" icon="SportzInteractiveLogoIcon" /> */}
        </NavLink>
      </li>
      {mainRoutes.map((route) => (
        <li
          className="relative px-6 py-2"
          key={route.name}
          onClick={handleIsSidebarOpen}
        >
          <NavLink
            exact
            to={route.path}
            className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150"
            aria-label={route.name}
          >
            <Route path={route.path} exact={route.exact}>
              <span className="absolute inset-y-0 left-0 w-1 rounded-tr-lg rounded-br-lg bg-light-green" />
            </Route>
            <SvgIcon className="w-5 h-5" icon={route.icon} />
            <span className="ml-4">{route.name}</span>
          </NavLink>
        </li>
      ))}

      <li className="flex justify-center py-2">
        <a
          href="https://discord.gg/SMsZ9Hx4d5"
          target="_blank"
          className="flex items-center px-4 mx-auto rounded cursor-pointer bg-default-orange"
          rel="noreferrer"
          aria-label="Join Discord"
        >
          <SvgIcon className="w-24 h-8" icon="DiscordIcon" />
        </a>
      </li>
    </ul>
  </div>
);

export default SidebarContent;
