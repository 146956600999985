import { Route, BrowserRouter } from "react-router-dom";
import { LayoutContainer } from "../domains/Layout/containers";

function App() {
  return (
    <BrowserRouter>
      <Route path="/" component={LayoutContainer} />
    </BrowserRouter>
  );
}

export default App;
