import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/app-main/";
import Loader from "./app/domains/common/Loader/Loader";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <div className="scroll-smooth">
        <App />
      </div>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
