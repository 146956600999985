import Sidebar from "./Sidebar";
import SidebarOverlay from "./SidebarOverlay";

const NavSidebar = ({ isSidebarOpen, handleIsSidebarOpen }) => (
  <>
    <SidebarOverlay
      isSidebarOpen={isSidebarOpen}
      handleIsSidebarOpen={handleIsSidebarOpen}
    />
    <Sidebar
      isSidebarOpen={isSidebarOpen}
      handleIsSidebarOpen={handleIsSidebarOpen}
    />
  </>
);

export default NavSidebar;
