import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { mainRoutes } from "../../../../router/sidebar";
import SvgIcon from "../../../common/SvgIcon";

const Header = ({ handleIsSidebarOpen }) => {
  const twTheme = localStorage.getItem("twTheme");
  const [mode, setMode] = useState(twTheme || "light");
  const htmlElement = document.querySelector("html");

  useEffect(() => {
    if (!localStorage.getItem("twTheme")) {
      htmlElement.classList.add("light");
      localStorage.setItem("twTheme", "light");
      setMode("light");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDark = () => {
    if (localStorage.getItem("twTheme") === "dark") {
      htmlElement.classList.remove("dark");
      localStorage.setItem("twTheme", "light");
      setMode("light");
    } else {
      htmlElement.classList.add("dark");
      localStorage.setItem("twTheme", "dark");
      setMode("dark");
    }
  };

  return (
    <header className="z-40 py-2 shadow-lg md:py-4 bg-light-mode dark:bg-dark-mode">
      <div className="flex items-center h-full px-3 text-theme-color-light dark:text-theme-color-dark">
        {/* <!-- Mobile hamburger --> */}
        <button
          onClick={handleIsSidebarOpen}
          className="p-1 -ml-1 rounded-md text-light-green md:hidden focus:outline-none focus:shadow-outline-green-200"
          aria-label="Menu"
        >
          <SvgIcon className="w-6 h-6" icon="MenuIcon" />
        </button>

        <div className="flex justify-center flex-1 h-10 md:justify-start">
          <NavLink to="/" className="flex items-center">
            <SvgIcon className="h-16 cursor-pointer lg:ml-0" icon="LogoIcon" />
            <span className="flex items-center text-2xl font-semibold">
              <span className="text-dark-green">NextGen</span>
              <span className=" text-default-orange">Dev</span>
            </span>
          </NavLink>
        </div>
        <ul className="items-center flex-shrink-0 hidden mr-3 space-x-6 cursor-pointer md:flex ">
          {mainRoutes.map(({ path, name }) => (
            <li
              className="text-xl font-extrabold text-dark-green hover:text-light-green"
              key={path}
            >
              <NavLink activeClassName="text-default-orange" to={path}>
                {name}
              </NavLink>
            </li>
          ))}
        </ul>
        <span className="relative text-theme-color-light dark:text-theme-color-dark">
          <button
            onClick={toggleDark}
            className="relative align-middle rounded-md focus:outline-none"
            aria-label="Notifications"
            aria-haspopup="true"
          >
            {mode === "light" ? (
              <SvgIcon className="w-6 h-6 mx-auto" icon="MoonIcon" />
            ) : (
              <SvgIcon className="w-6 h-6 mx-auto" icon="SunIcon" />
            )}

            {/* <!-- Notification badge --> */}
            {/* <span className="absolute top-0 right-0 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-full animate-ping dark:border-gray-800" /> */}
          </button>
        </span>
      </div>
    </header>
  );
};

export default Header;
